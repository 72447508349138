<template>
  <div class="search-group">
    <b-select
      v-model="selecionado"
      name="filtroSelecao"
      class="search-items"
      @select="placeholder = false"
      @input="enviarParaPai()"
    >
      <option
        :value="null"
        disabled
        hidden
      >{{ filtroNome }}</option>

      <option
        key="_blank"
        :value="null"
      />

      <option
        v-for="(filtro, index) in filtros"
        :key="index"
        :value="filtro"
        :class="filtro.classe"
      >
        <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        > {{ filtro.label }} </b-form-checkbox>
        <!-- <input
          :id="index"
          v-model="aaaaa"
          type="checkbox"
        > -->
      </option>
    </b-select>
    <!-- :class="{'opcao-titulo': filtro.titulo}"
      :disabled="filtro.titulo" -->
    <label
      name="filtroLabel"
      for="filter"
      class="fas fa-filter icon"
    />
  </div>
</template>
<style >
.opcao-titulo {
  font-weight: bold;
}
</style>
<script>
export default {
  props: {
    filtros: {
      type: [Array, Boolean],
      default: false,
    },
    filtroAtual: {
      type: Object,
      default: () => {},
    },
    filtroNome: {
      type: String,
      default: 'Filtro',
    },
  },
  data() {
    return {
      placeholder: false,
      selecionado: null,
      status: null,
    }
  },
  watch: {
    filtroAtual(val, oldVal) {
      if (val !== oldVal) {
        this.selecionado = val
        this.enviarParaPai()
      }
    },
  },
  created() {
    if (this.filtroAtual) {
      this.selecionado = this.filtroAtual
      this.enviarParaPai()
    }
  },
  methods: {
    enviarParaPai() {
      this.$emit('selecionado', this.selecionado)
    },
  },
}
</script>
